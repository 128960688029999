import { useCallback } from 'react';
import { useMutation, type UseMutationConfig } from 'react-relay';
import {
  type Disposable,
  type GraphQLTaggedNode,
  type MutationParameters,
  type PayloadError,
} from 'relay-runtime';

export type ApiError = {
  __typename: string;
  message: string;
};

type NonEmptyArray<T> = [T, ...T[]];

type ContraApiError = ApiError & { operation: string };

type ContraMutationErrors =
  | {
      apiErrors: NonEmptyArray<ContraApiError>;
      hasErrors: true;
      payloadErrors: null;
    }
  | {
      apiErrors: null;
      hasErrors: false;
      payloadErrors: null;
    }
  | {
      apiErrors: null;
      hasErrors: true;
      payloadErrors: NonEmptyArray<PayloadError>;
    };

type ContraMutationResponse = {
  [mutation: string]: { errors?: readonly ApiError[] | null };
};

type ContraMutationParameters = MutationParameters & {
  response: ContraMutationResponse;
};

// reversing the order of the errors and response to the node style of errors first
// makes it a little bit harder to ignore errors, if you want to access the mutation response.
type ContraMutationOnCompleteCallback<
  TMutation extends ContraMutationParameters,
> = (errors: ContraMutationErrors, response: TMutation['response']) => void;

type UseContraMutationHandlers<TMutation extends ContraMutationParameters> = {
  onCompleted: ContraMutationOnCompleteCallback<TMutation>;
  onError: (error: Error) => void;
};

type UseContraMutationConfig<TMutation extends ContraMutationParameters> = Omit<
  UseMutationConfig<TMutation>,
  'onCompleted' | 'onError'
> &
  UseContraMutationHandlers<TMutation>;

type CommitContraMutationFunction<TMutation extends ContraMutationParameters> =
  (config: UseContraMutationConfig<TMutation>) => Disposable;

const isNonEmptyArray = <T>(as: T[]): as is NonEmptyArray<T> => as.length > 0;

const getMutationAPIErrors = <TMutationResponse extends ContraMutationResponse>(
  mutationResponse: TMutationResponse,
): ContraApiError[] => {
  const mutationErrors: ContraApiError[] = Object.entries(
    mutationResponse,
  ).flatMap(([operation, { errors }]) =>
    errors
      ? errors.map((error: ApiError) => ({
          ...error,
          operation,
        }))
      : [],
  );

  return mutationErrors;
};

const getMutationErrors = <TMutationResponse extends ContraMutationResponse>(
  response: TMutationResponse,
  payloadErrors: PayloadError[] | null,
): ContraMutationErrors => {
  if (payloadErrors && isNonEmptyArray(payloadErrors)) {
    return {
      apiErrors: null,
      hasErrors: true,
      payloadErrors,
    };
  }

  const apiErrors = getMutationAPIErrors(response);
  if (isNonEmptyArray(apiErrors)) {
    return {
      apiErrors,
      hasErrors: true,
      payloadErrors: null,
    };
  }

  return {
    apiErrors: null,
    hasErrors: false,
    payloadErrors: null,
  };
};

export const useContraMutation = <TMutation extends ContraMutationParameters>(
  mutation: GraphQLTaggedNode,
): [
  commitMutation: CommitContraMutationFunction<TMutation>,
  areMutationsInFlight: boolean,
] => {
  const [commitMutation, areMutationsInFlight] =
    useMutation<TMutation>(mutation);

  const contraCommitMutation: CommitContraMutationFunction<TMutation> =
    useCallback(
      (config) => {
        const {
          onCompleted: onContraCompleted,
          onError: onContraError,
          ...otherConfigValues
        } = config;

        return commitMutation({
          onCompleted: (response, payloadErrors) => {
            const errors = getMutationErrors<TMutation['response']>(
              response,
              payloadErrors,
            );

            onContraCompleted(errors, response);
          },
          onError: (error) => {
            onContraError(error);
          },
          ...otherConfigValues,
        });
      },
      [commitMutation],
    );

  return [contraCommitMutation, areMutationsInFlight];
};
