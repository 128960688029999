import { type CustomThemeConfigType } from '../types.js';
import {
  type TemplateNames,
  type ThemeNames,
} from '@/templates/templateTypes.js';
import { storage } from '@/utilities/storage.js';
import { dayjs } from '@contra/date';
import { safeStringify } from '@contra/utilities/safeStringify';

const customizationStorage = storage('local');

type StoredCustomization = CustomThemeConfigType & {
  /**
   * ISO 8601 string. Represents the time when the customization expires
   * and should be ignored and removed from storage.
   */
  expiry: string;
};

type GetStorageKeyOptions = {
  templateName: TemplateNames;
  themeName: ThemeNames;
  userId?: string;
};

const getStorageKey = ({
  templateName,
  themeName,
  userId = 'UNKNOWN_USER',
}: GetStorageKeyOptions): string => {
  return `CCP_CUSTOM_THEME_CONFIG_${userId}_${templateName}_${themeName}`;
};

export const setStorageCustomization = ({
  customization,
  templateName,
  themeName,
  userId,
}: GetStorageKeyOptions & {
  customization: CustomThemeConfigType;
}): void => {
  const expiry = dayjs().add(1, 'day').toISOString();
  const toSave: StoredCustomization = { ...customization, expiry };
  const savedCustomization = safeStringify(toSave);

  customizationStorage.setItem(
    getStorageKey({ templateName, themeName, userId }),
    savedCustomization,
  );
};

export const removeStorageCustomization = ({
  templateName,
  themeName,
  userId,
}: GetStorageKeyOptions): void => {
  customizationStorage.removeItem(
    getStorageKey({ templateName, themeName, userId }),
  );
};

export const getStorageCustomization = ({
  templateName,
  themeName,
  userId,
}: GetStorageKeyOptions): CustomThemeConfigType | null => {
  const savedCustomization = customizationStorage.getItem(
    getStorageKey({ templateName, themeName, userId }),
  );

  if (!savedCustomization) {
    return null;
  }

  const { expiry, ...customization }: StoredCustomization =
    JSON.parse(savedCustomization);

  const isExpired = dayjs().isAfter(dayjs(expiry));

  if (isExpired) {
    removeStorageCustomization({ templateName, themeName, userId });

    return null;
  }

  return customization;
};
