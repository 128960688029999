import { DEFAULT_THEME_CONFIG } from '../constants.js';
import { type CustomThemeConfigType } from '../types.js';
import { getStorageCustomization } from './customThemeStorage.js';
import { generateThemeConfigFromThemeCustomization } from './generateThemeConfigFromThemeCustomization.js';
import { type UserProfile_userProfile$data } from '@/__generated__/relay/UserProfile_userProfile.graphql.js';
import {
  type TemplateNames,
  type ThemeNames,
} from '@/templates/templateTypes.js';
import { type Logger } from 'roarr';

type GetCustomizedThemeConfigOptions = {
  currentThemeId: string | null;
  log: Logger;
  templateName: TemplateNames;
  themeName: ThemeNames;
  userProfile: UserProfile_userProfile$data | null;
};

/**
 * Returns a customized theme config object to be used.
 *
 * First we check if there is a saved theme config in storage, if so, we use that.
 * If not, we then check if the queried theme customization for the visitor is available.
 * If so, we use that by generating a config from the queried theme customization.
 *
 * If none of the above is available, we use the default theme config.
 */
export const getCustomizedThemeConfig = ({
  currentThemeId,
  log,
  templateName,
  themeName,
  userProfile,
}: GetCustomizedThemeConfigOptions): CustomThemeConfigType => {
  if (userProfile?.visitorCanEdit) {
    const userId = userProfile.id;
    const savedCustomization = getStorageCustomization({
      templateName,
      themeName,
      userId,
    });

    if (savedCustomization) {
      log.info('Using saved customization from storage.');

      return savedCustomization;
    }
  }

  // If the user has a theme customization for the current template and theme from API, use that.
  if (
    currentThemeId &&
    userProfile?.independentPortfolioTemplateTheme?.themeCustomization &&
    userProfile.independentPortfolioTemplateTheme.id === currentThemeId
  ) {
    log.info(
      'Using customization from user profile for matching template/theme.',
    );

    return generateThemeConfigFromThemeCustomization(
      userProfile.independentPortfolioTemplateTheme.themeCustomization,
    );
  }

  log.info('Returning default theme config.');

  return DEFAULT_THEME_CONFIG;
};
