import { BACKGROUND_STYLE_DEFAULT_ID } from '../constants.js';
import {
  type TemplateNames,
  type ThemeNames,
} from '@/templates/templateTypes.js';
import { themeRegistry } from '@/templates/themeRegistry.js';
import { BaseThemeTokens, ThemeObject } from '@/templates/types.js';

const getValuesFromTheme = (theme: ThemeObject<BaseThemeTokens>) => ({
  backgroundColor: theme.colors?.pageBackground?.value,
  backgroundStyleId: BACKGROUND_STYLE_DEFAULT_ID,
  fontColorPrimary: theme.colors?.defaultFont?.value,
  fontColorSecondary: theme.colors?.secondaryFont?.value,
  fontFamilyAccent: theme.fonts?.accent?.value,
  fontFamilyBody: theme.fonts?.body?.value,
  fontFamilyHeading: theme.fonts?.heading?.value,
  logoImage: null,
});

export const getDefaultThemeValues = ({
  templateName,
  themeName,
}: {
  templateName: TemplateNames;
  themeName: ThemeNames;
}) => {
  return getValuesFromTheme(themeRegistry[templateName][themeName]);
};
