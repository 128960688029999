import {
  type CSS_VARIABLES_MAP_TYPE,
  type CUSTOM_CONFIG_FONT_PROPERTIES_MAP,
  type FONT_STYLE_TYPE_LABELS,
} from './types.js';
import { type AllFonts, fontStacks } from '@/stitches/fonts.js';

export const FONT_STYLE_LABELS: FONT_STYLE_TYPE_LABELS = {
  accent: 'Accent',
  body: 'Body',
  headline: 'Headline',
};

export const FONT_STYLE_KEYS: FONT_STYLE_TYPE_LABELS = {
  accent: 'Accent',
  body: 'Body',
  headline: 'Heading',
};

const HEADLINE_FONTS = [
  fontStacks['butler'],
  fontStacks['cabinet'],
  fontStacks['clashDisplay'],
  fontStacks['gambarino'],
  fontStacks['generalSans'],
  fontStacks['neco'],
  fontStacks['panchang'],
  fontStacks['satoshi'],
  fontStacks['switzer'],
  fontStacks['syne'],
  fontStacks['zodiak'],
];

const BODY_FONTS = [
  fontStacks['cabinet'],
  fontStacks['epilogue'],
  fontStacks['generalSans'],
  fontStacks['spaceGrotesk'],
  fontStacks['switzer'],
  fontStacks['workSans'],
  fontStacks['satoshi'],
];

const ACCENT_FONTS = [
  fontStacks['anton'],
  fontStacks['cabinet'],
  fontStacks['clashDisplay'],
  fontStacks['panchang'],
];

type API_FONT_MAP_TYPE = {
  ANTON: {
    id: string;
    name: AllFonts;
    stackValue: string;
  };
  BUTLER: {
    id: string;
    name: AllFonts;
    stackValue: string;
  };
  CABINET: {
    id: string;
    name: AllFonts;
    stackValue: string;
  };
  CLASH: {
    id: string;
    name: AllFonts;
    stackValue: string;
  };
  EPILOGUE: {
    id: string;
    name: AllFonts;
    stackValue: string;
  };
  GAMBARINO: {
    id: string;
    name: AllFonts;
    stackValue: string;
  };
  GENERAL_SANS: {
    id: string;
    name: AllFonts;
    stackValue: string;
  };
  GILDA: {
    id: string;
    name: AllFonts;
    stackValue: string;
  };
  NECO: {
    id: string;
    name: AllFonts;
    stackValue: string;
  };
  PANCHANG: {
    id: string;
    name: AllFonts;
    stackValue: string;
  };
  SPACE_GROTESK: {
    id: string;
    name: AllFonts;
    stackValue: string;
  };
  SWITZER: {
    id: string;
    name: AllFonts;
    stackValue: string;
  };
  SYNE: {
    id: string;
    name: AllFonts;
    stackValue: string;
  };
  WORK_SANS: {
    id: string;
    name: AllFonts;
    stackValue: string;
  };
  ZODIAK: {
    id: string;
    name: AllFonts;
    stackValue: string;
  };
};

export const API_FONT_MAP: API_FONT_MAP_TYPE = {
  ANTON: {
    id: 'WyJUaGVtZUZvbnRQYWNrIiw0M10=',
    name: 'Anton',
    stackValue: fontStacks['anton'].value,
  },
  BUTLER: {
    id: 'WyJUaGVtZUZvbnRQYWNrIiwzMF0=',
    name: 'Butler',
    stackValue: fontStacks['butler'].value,
  },
  CABINET: {
    id: 'WyJUaGVtZUZvbnRQYWNrIiwzMV0=',
    name: 'Cabinet',
    stackValue: fontStacks['cabinet'].value,
  },
  CLASH: {
    id: 'WyJUaGVtZUZvbnRQYWNrIiwzMl0=',
    name: 'Clash',
    stackValue: fontStacks['clashDisplay'].value,
  },
  EPILOGUE: {
    id: 'WyJUaGVtZUZvbnRQYWNrIiw0MF0=',
    name: 'Epilogue',
    stackValue: fontStacks['epilogue'].value,
  },
  GAMBARINO: {
    id: 'WyJUaGVtZUZvbnRQYWNrIiwzM10=',
    name: 'Gambarino',
    stackValue: fontStacks['gambarino'].value,
  },
  GENERAL_SANS: {
    id: 'WyJUaGVtZUZvbnRQYWNrIiwzNF0=',
    name: 'General Sans',
    stackValue: fontStacks['generalSans'].value,
  },
  GILDA: {
    id: 'WyJUaGVtZUZvbnRQYWNrIiwzNF0=',
    name: 'Gilda',
    stackValue: fontStacks['gilda'].value,
  },
  NECO: {
    id: 'WyJUaGVtZUZvbnRQYWNrIiwzNV0=',
    name: 'Neco',
    stackValue: fontStacks['neco'].value,
  },
  PANCHANG: {
    id: 'WyJUaGVtZUZvbnRQYWNrIiwzNl0=',
    name: 'Panchang',
    stackValue: fontStacks['panchang'].value,
  },
  SPACE_GROTESK: {
    id: 'WyJUaGVtZUZvbnRQYWNrIiw0MV0=',
    name: 'Space Grotesk',
    stackValue: fontStacks['spaceGrotesk'].value,
  },
  SWITZER: {
    id: 'WyJUaGVtZUZvbnRQYWNrIiwzN10=',
    name: 'Switzer',
    stackValue: fontStacks['switzer'].value,
  },
  SYNE: {
    id: 'WyJUaGVtZUZvbnRQYWNrIiwzOF0=',
    name: 'Syne',
    stackValue: fontStacks['syne'].value,
  },
  WORK_SANS: {
    id: 'WyJUaGVtZUZvbnRQYWNrIiw0Ml0=',
    name: 'Work Sans',
    stackValue: fontStacks['workSans'].value,
  },
  ZODIAK: {
    id: 'WyJUaGVtZUZvbnRQYWNrIiwzOV0=',
    name: 'Zodiak',
    stackValue: fontStacks['zodiak'].value,
  },
};

export const FONTS_BY_TYPE = {
  accent: ACCENT_FONTS,
  body: BODY_FONTS,
  headline: HEADLINE_FONTS,
};

export const CSS_VARIABLES_MAP: CSS_VARIABLES_MAP_TYPE = {
  accent: '--fonts-accent',
  body: '--fonts-body',
  headline: '--fonts-heading',
};

export const CUSTOM_CONFIG_FONT_PROPERTIES: CUSTOM_CONFIG_FONT_PROPERTIES_MAP =
  {
    accent: 'fontFamilyAccent',
    body: 'fontFamilyBody',
    headline: 'fontFamilyHeading',
  };
