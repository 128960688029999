import { ALL_BACKGROUND_STYLE_IDS, FONT_PACK_NONE_ID } from '../constants.js';
import {
  type BackgroundStyleName,
  type CustomThemeConfigType,
} from '../types.js';
import { type UserProfile_userProfile$data } from '@/__generated__/relay/UserProfile_userProfile.graphql.js';
import { API_FONT_MAP } from '@/components/PlatformSidebar/components/FontSelector/constants.js';
import { isAccentFont, isBodyFont, isHeadlineFont } from '@/stitches/fonts.js';

const BACKGROUND_STYLE_KEYS = Object.keys(ALL_BACKGROUND_STYLE_IDS);
// TODO: API_FONT_MAP needs to be generated from the API.
const API_FONT_MAP_VALUES = Object.freeze(Object.values(API_FONT_MAP));

const getBackgroundStyleName = (nid: string): BackgroundStyleName => {
  if (BACKGROUND_STYLE_KEYS.includes(nid)) {
    return nid as BackgroundStyleName;
  }

  return 'NONE';
};

const getFontPackStack = (fontPackId: string): string | null => {
  const fontPackEntry = API_FONT_MAP_VALUES.find((pack) => {
    return pack.id === fontPackId;
  });

  return fontPackEntry ? fontPackEntry.stackValue : null;
};

function getFontFamily(
  fontFamily: 'accent',
  id: string | undefined,
): CustomThemeConfigType['fontFamilyAccent'] | undefined;
function getFontFamily(
  fontFamily: 'body',
  id: string | undefined,
): CustomThemeConfigType['fontFamilyBody'] | undefined;
function getFontFamily(
  fontFamily: 'headline',
  id: string | undefined,
): CustomThemeConfigType['fontFamilyHeading'] | undefined;
// eslint-disable-next-line func-style
function getFontFamily(
  fontFamily: 'accent' | 'body' | 'headline',
  id: string | undefined,
) {
  if (id) {
    const value = getFontPackStack(id);

    if (value) {
      const fontPackEntry = API_FONT_MAP_VALUES.find((pack) => {
        return pack.id === id;
      });

      if (!fontPackEntry) {
        return {
          value,
        };
      }

      const { name } = fontPackEntry;

      switch (fontFamily) {
        case 'accent': {
          return {
            name: isAccentFont(name) ? name : undefined,
            value,
          };
        }

        case 'body': {
          return {
            name: isBodyFont(name) ? name : undefined,
            value,
          };
        }

        case 'headline': {
          return {
            name: isHeadlineFont(name) ? name : undefined,
            value,
          };
        }
      }
    }
  }

  return undefined;
}

export type ThemeCustomization = NonNullable<
  NonNullable<
    UserProfile_userProfile$data['independentPortfolioTemplateTheme']
  >['themeCustomization']
>;

/**
 * Takes a theme customization object from the API and returns a theme config object.
 */
export const generateThemeConfigFromThemeCustomization = (
  themeCustomization: ThemeCustomization,
): CustomThemeConfigType => {
  return {
    accentFontId: themeCustomization.accentFontPack?.id ?? FONT_PACK_NONE_ID,
    backgroundColor: themeCustomization.backgroundColor,
    backgroundStyleId: themeCustomization.backgroundStyle.id,
    backgroundStyleName: getBackgroundStyleName(
      themeCustomization.backgroundStyle.nid,
    ),
    bodyFontId: themeCustomization.bodyFontPack?.id ?? FONT_PACK_NONE_ID,
    fontColorPrimary: themeCustomization.fontColorPrimary,
    fontColorSecondary: themeCustomization.fontColorSecondary,
    fontFamilyAccent: getFontFamily(
      'accent',
      themeCustomization.accentFontPack?.id,
    ),
    fontFamilyBody: getFontFamily('body', themeCustomization.bodyFontPack?.id),
    fontFamilyHeading: getFontFamily(
      'headline',
      themeCustomization.headingFontPack?.id,
    ),
    headingFontId: themeCustomization.headingFontPack?.id ?? FONT_PACK_NONE_ID,
    logoImage: themeCustomization.logoImage,
    projectsVariantId: themeCustomization.layout.projectsVariant.id,
    servicesVariantId: themeCustomization.layout.servicesVariant.id,
  };
};
