import { THEME_CONFIG_TOKEN_MAP } from '../constants.js';
import { type CustomThemeConfigType } from '../types.js';

// TODO:
// Add `satisfies ReadonlyArray<keyof ThemeConfigTokenMap>` to then end of this
// Once Next.js SWC supports the TS operator.
const CUSTOMIZATION_KEYS = [
  'backgroundColor',
  'fontColorPrimary',
  'fontColorSecondary',
  'fontFamilyAccent',
  'fontFamilyBody',
  'fontFamilyHeading',
] as const;

export const getStyleContents = (
  customTheme: CustomThemeConfigType,
  parentClassSelector: string,
): string => {
  const cssVariables: string[] = [];

  [...CUSTOMIZATION_KEYS]
    .sort((a, b) => a.localeCompare(b))
    .forEach((key) => {
      const value = customTheme[key];

      if (value) {
        cssVariables.push(
          `${THEME_CONFIG_TOKEN_MAP[key]}: ${
            typeof value === 'object' ? value.value : value
          };`,
        );
      }
    });

  if (cssVariables.length <= 0) {
    return '';
  }

  return `.${parentClassSelector} { ${cssVariables.join(' ')} }`;
};
