import { THEME_CONFIG_TOKEN_MAP } from '../constants.js';
import { type CustomThemeConfigType } from '../types.js';
import { getStorageCustomization } from './customThemeStorage.js';
import { generateThemeConfigFromThemeCustomization } from './generateThemeConfigFromThemeCustomization.js';
import { getDefaultThemeValues } from './getDefaultThemeValues.js';
import { type UserProfile_userProfile$data } from '@/__generated__/relay/UserProfile_userProfile.graphql.js';
import {
  type TemplateNames,
  type ThemeNames,
} from '@/templates/templateTypes.js';

type ThemeConfigDiff = Partial<{
  [key in
    | keyof typeof THEME_CONFIG_TOKEN_MAP
    | 'backgroundStyleId'
    | 'logoImage']: string | null | undefined;
}>;

/**
 * Compares two theme configurations, checking whether the all keys are present and equal.
 * @param a The changed theme to compare. Usually the user's custom theme, fetched either from the API or local storage.
 * @param b The base theme to compare to. Usually the default (unmodified) theme.
 */
const isThemeEqual = <T extends Record<string, unknown> & ThemeConfigDiff>(
  a: T,
  b: T,
): boolean => {
  const aKeys = Object.keys(a);
  return aKeys.every((key) => {
    const aValue = a[key];
    const bValue = b[key];

    // Use case insensitive comparison when comparing values for color variables.
    // This avoids false positives when comparing colors like `#ffffff` and `#FFFFFF`.
    return key.toLowerCase().includes('color') &&
      typeof aValue === 'string' &&
      typeof bValue === 'string'
      ? aValue?.toLowerCase() === bValue?.toLowerCase()
      : aValue === bValue;
  });
};

/**
 * Filter out any values that are not present in the THEME_CONFIG objects,
 * as well as empty/falsy values.
 */
export const filteredThemeConfig = (
  themeObject: CustomThemeConfigType | null,
): ThemeConfigDiff | null => {
  if (!themeObject) {
    return null;
  }

  const filtered = Object.entries(themeObject).reduce(
    (filteredConfig: ThemeConfigDiff, [key, value]) => {
      return [
        ...Object.keys({
          ...THEME_CONFIG_TOKEN_MAP,
        }),
        'logoImage',
        'backgroundStyleId',
      ].includes(key) && Boolean(value)
        ? {
            ...filteredConfig,
            [key]:
              typeof value === 'string'
                ? value
                : value !== null && 'value' in value
                  ? value.value
                  : value?.uid,
          }
        : filteredConfig;
    },
    {},
  );

  return Object.keys(filtered).length > 0 ? filtered : null;
};

/**
 * Given a template and theme name, and a user profile, determine whether there are any changes
 * to the theme customizations.
 *
 * `customChanges` indicates if a user has modified the default theme. Is true for both published and unpublished changes.
 *
 * `unpublishedChanges` indicates if a user has made any changes to the published theme,
 * including if user reverts to the default theme styles after publishing custom changed.
 *
 */
export const getThemeChanges = ({
  customization,
  templateName,
  themeName,
  userProfile,
}: {
  customization?: CustomThemeConfigType;
  templateName: TemplateNames;
  themeName: ThemeNames;
  userProfile: UserProfile_userProfile$data | null;
}): {
  customChanges: boolean;
  unpublishedChanges: boolean;
} => {
  const defaultTheme = getDefaultThemeValues({ templateName, themeName });

  const publishedTheme = userProfile?.independentPortfolioTemplateTheme
    ?.themeCustomization
    ? generateThemeConfigFromThemeCustomization(
        userProfile.independentPortfolioTemplateTheme.themeCustomization,
      )
    : null;
  const fromAPI = filteredThemeConfig(publishedTheme);

  // Use the passed in customization if it exists, otherwise fetch from local storage.
  const themeOverrides = filteredThemeConfig(
    customization ??
      getStorageCustomization({
        templateName,
        themeName,
        userId: userProfile?.id,
      }),
  );

  const customChanges = !isThemeEqual(
    themeOverrides ?? fromAPI ?? {},
    defaultTheme,
  );

  // Compare both the number of theme options changed as well as the values of the options.
  // This accounts for the case where a user has reverted to the default theme and the local values are empty.
  const unpublishedChanges =
    themeOverrides &&
    Object.keys(themeOverrides).length !== Object.keys(fromAPI ?? {}).length
      ? true
      : !isThemeEqual(themeOverrides ?? {}, { ...defaultTheme, ...fromAPI });

  return {
    customChanges,
    unpublishedChanges,
  };
};
