/**
 * @generated SignedSource<<ce6b69c7abe75f30ec13cb1f4906f3b3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateThemeCustomizationInput = {
  accentFontPackId?: string | null;
  backgroundColor: string;
  backgroundStyleId: string;
  bodyFontPackId?: string | null;
  fontColorPrimary: string;
  fontColorSecondary: string;
  fontPackId?: string | null;
  headingFontPackId?: string | null;
  independentPortfolioTemplateThemeId: string;
  layout: CreateCustomizationLayoutInput;
  logoImage?: UploadedImageInput | null;
  logoImageId?: string | null;
};
export type CreateCustomizationLayoutInput = {
  projectsVariantId: string;
  servicesVariantId: string;
};
export type UploadedImageInput = {
  animated: boolean;
  checksum?: string | null;
  height: number;
  originalFormat: string;
  resourceType?: string | null;
  sizeBytes: number;
  uid: string;
  width: number;
};
export type CustomThemeConfigContextMutation$variables = {
  input: CreateThemeCustomizationInput;
};
export type CustomThemeConfigContextMutation$data = {
  readonly createThemeCustomization: {
    readonly errors: ReadonlyArray<{
      readonly __typename: string;
      readonly message: string;
    }> | null;
    readonly userAccount: {
      readonly profile: {
        readonly displayUsername: string;
        readonly id: string;
        readonly independentPortfolioTemplateTheme: {
          readonly themeCustomization: {
            readonly accentFontPack: {
              readonly id: string;
              readonly nid: string;
            } | null;
            readonly backgroundColor: string;
            readonly backgroundStyle: {
              readonly id: string;
              readonly nid: string;
            };
            readonly bodyFontPack: {
              readonly id: string;
              readonly nid: string;
            } | null;
            readonly fontColorPrimary: string;
            readonly fontColorSecondary: string;
            readonly fontPack: {
              readonly id: string;
              readonly nid: string;
            };
            readonly headingFontPack: {
              readonly id: string;
              readonly nid: string;
            } | null;
            readonly id: string;
            readonly layout: {
              readonly projectsVariant: {
                readonly id: string;
                readonly nid: string;
              };
              readonly servicesVariant: {
                readonly id: string;
                readonly nid: string;
              };
            };
            readonly logoImage: {
              readonly id: string;
              readonly sizeBytes: number;
              readonly uid: string;
            } | null;
          } | null;
        } | null;
      };
    } | null;
  };
};
export type CustomThemeConfigContextMutation = {
  response: CustomThemeConfigContextMutation$data;
  variables: CustomThemeConfigContextMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayUsername",
  "storageKey": null
},
v6 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "nid",
    "storageKey": null
  }
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "ThemeCustomization",
  "kind": "LinkedField",
  "name": "themeCustomization",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ThemeFontPack",
      "kind": "LinkedField",
      "name": "accentFontPack",
      "plural": false,
      "selections": (v6/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "backgroundColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ThemeBackgroundStyle",
      "kind": "LinkedField",
      "name": "backgroundStyle",
      "plural": false,
      "selections": (v6/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ThemeFontPack",
      "kind": "LinkedField",
      "name": "bodyFontPack",
      "plural": false,
      "selections": (v6/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fontColorPrimary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fontColorSecondary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ThemeFontPack",
      "kind": "LinkedField",
      "name": "fontPack",
      "plural": false,
      "selections": (v6/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ThemeFontPack",
      "kind": "LinkedField",
      "name": "headingFontPack",
      "plural": false,
      "selections": (v6/*: any*/),
      "storageKey": null
    },
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomizationLayout",
      "kind": "LinkedField",
      "name": "layout",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ThemeProjectLayout",
          "kind": "LinkedField",
          "name": "projectsVariant",
          "plural": false,
          "selections": (v6/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ThemeServiceLayout",
          "kind": "LinkedField",
          "name": "servicesVariant",
          "plural": false,
          "selections": (v6/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContraImage",
      "kind": "LinkedField",
      "name": "logoImage",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "uid",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sizeBytes",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = [
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomThemeConfigContextMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateThemeCustomizationPayload",
        "kind": "LinkedField",
        "name": "createThemeCustomization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAccount",
            "kind": "LinkedField",
            "name": "userAccount",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserProfile",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "IndependentPortfolioTemplateTheme",
                    "kind": "LinkedField",
                    "name": "independentPortfolioTemplateTheme",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CustomThemeConfigContextMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateThemeCustomizationPayload",
        "kind": "LinkedField",
        "name": "createThemeCustomization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": (v8/*: any*/),
                "type": "CollaboratorNotFoundError",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v8/*: any*/),
                "type": "CollaboratorRoleCountInvalidError",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v8/*: any*/),
                "type": "OrganizationNotFoundError",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v8/*: any*/),
                "type": "ProductizedServiceCollaboratorNotFound",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v8/*: any*/),
                "type": "ReferBasicOpportunityUserNotFound",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v8/*: any*/),
                "type": "SubmitJoinOrganizationRequestError",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAccount",
            "kind": "LinkedField",
            "name": "userAccount",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserProfile",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "IndependentPortfolioTemplateTheme",
                    "kind": "LinkedField",
                    "name": "independentPortfolioTemplateTheme",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "041275016330ee94943018424c6fde44",
    "id": null,
    "metadata": {},
    "name": "CustomThemeConfigContextMutation",
    "operationKind": "mutation",
    "text": "mutation CustomThemeConfigContextMutation(\n  $input: CreateThemeCustomizationInput!\n) {\n  createThemeCustomization(input: $input) {\n    errors {\n      __typename\n      message\n      ... on CollaboratorNotFoundError {\n        id\n      }\n      ... on CollaboratorRoleCountInvalidError {\n        id\n      }\n      ... on OrganizationNotFoundError {\n        id\n      }\n      ... on ProductizedServiceCollaboratorNotFound {\n        id\n      }\n      ... on ReferBasicOpportunityUserNotFound {\n        id\n      }\n      ... on SubmitJoinOrganizationRequestError {\n        id\n      }\n    }\n    userAccount {\n      profile {\n        id\n        displayUsername\n        independentPortfolioTemplateTheme {\n          themeCustomization {\n            accentFontPack {\n              id\n              nid\n            }\n            backgroundColor\n            backgroundStyle {\n              id\n              nid\n            }\n            bodyFontPack {\n              id\n              nid\n            }\n            fontColorPrimary\n            fontColorSecondary\n            fontPack {\n              id\n              nid\n            }\n            headingFontPack {\n              id\n              nid\n            }\n            id\n            layout {\n              projectsVariant {\n                id\n                nid\n              }\n              servicesVariant {\n                id\n                nid\n              }\n            }\n            logoImage {\n              id\n              uid\n              sizeBytes\n            }\n          }\n          id\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "85a0155c3f7637a587b0b69e0f337780";

export default node;
